<template>
  <div>
    
    <!--begin::Tables Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
     
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">{{labels.consultantTypes[consultantType]+" Listesi"}}</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{recordcount}}</b> Danışman</span>
      </h3>
       
      
    </div>
    <!--end::Header-->
   
    <!--begin::Body-->
    <div class="card-body py-3">
      
      <Filter @filter="filter($event)" :consultantType="consultantType" />

       
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              
              <th class="">ID</th>
              <th class="min-w-150px">Adı Soyadı</th>
              <th>Uzmanlık</th>
              <th class="min-w-150px">Telefon</th>
              <th class="min-w-150px">E-posta</th>
             
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody v-if="consultants">
            <template v-for="(item, index) in consultants" :key="index">
              <tr>
                <td>
                  {{item.userId}}
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-45px me-5" v-if="item.profileImage && item.profileImage!='avatar.jpg'">
                      <img :src="'https://api.ytmdanismanlik.com/'+item.profileImageDirectory+item.profileImage" alt="" />
                    </div>
                    
                    <div class="d-flex justify-content-start flex-column">
                      <a :href="'/consultant/'+item.userId+'/settings'" class="text-dark fw-bolder text-hover-primary fs-6">{{ item.name }} {{ item.surname }} 
                        <span :class="'badge badge-light-'+statusses[item.status].class">{{statusses[item.status].label}}</span></a>
                    </div>
                    
                    
                    
                  </div>
                </td>
                <td class="text-dark fw-bolder">{{item.doctorType}}</td>
                <td>
                  <a class="text-dark fw-bolder text-hover-primary d-block fs-6"> +{{item.phonePrefix}} {{ formatPhoneNumber(item.phone) }}</a>  
                </td>
                <td>
                  <a class="text-dark fw-bolder text-hover-primary d-block fs-6"> {{item.email}}</a>
                    
                </td>

                <td>
                  <div class="d-flex justify-content-start flex-column text-dark fw-bolder text-hover-primary d-block fs-6">
                      {{item.gender ? genders[item.gender] : ''}}
                      <span class="text-muted fw-bold text-muted d-block fs-7" v-if="item.birthday">
                        {{Math.round(moment().diff(item.birthday,'years',true))}} Yaş
                        </span>
                       
                    </div>
                </td>

               
                

               
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <paging 
            v-if="recordcount>0" 
            :page="page" 
            :pagelength="pagelength" 
            :recordcount="recordcount" 
            @next="next" 
            @prev="prev"
            @gotoPage="gotoPage($event)"
            />
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
  <!--end::Tables Widget 9-->

  </div>
</template>

<script>


import api from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Paging from '@/components/BD/Paging.vue'
import labels from "@/core/config/labels";

import ConsultantToolbar from '@/components/actions/ConsultantToolbar.vue'
import Filter from '@/components/filters/ColsultantListFilter.vue'

import moment from 'moment'

export default {
  name: "Consultants",
  components:{
    Paging,
    Filter
  },
  data(){
    return {

      consultantFilter:{
          status:'JJ',
          filter_word:'',
          consultant_type:0
      }, 

      consultants:null,
      recordcount:null,
      genders:labels.genders,
      statusses : labels.statusses,
      labels:labels,
      pagelength:10,
      page:1,

      

      consultantType:5,

      registerChannels : {
        1:'Uygulama, Saat:',
        0:'Web, Saat:'
      }
    }
  },
  watch:{
      page:function(){
        this.getConsultantList();
      }
    },
  methods:{
    filter(filterObj){
      this.consultantFilter = filterObj;
      this.getConsultantList();
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + ' ' + match[3]+ ' ' + match[4];
      }
      return null;
    },

    moment(date){
      return moment(date);
    },
     getConsultantList(){
      let paging = {
        pagelength:this.pagelength,
        page:this.page
      };

      let filter={
          doctorType:this.consultantType,
          doctorStatus:this.consultantFilter.status,
          doctorName:this.consultantFilter.filter_word,
          doctorBranch:this.consultantFilter.consultant_type
      }
      
let pagelength = paging.pagelength;
  let page = paging.page;
  let doctorType = filter.doctorType;
  let doctorStatus = filter.doctorStatus ? filter.doctorStatus : 'JJ';
  let doctorBranch = filter.doctorBranch ? filter.doctorBranch : '0';
  let doctorName = filter.doctorName ? filter.doctorName : '-';

  let apiUrl = "/User/ListUsers/"+doctorStatus+"/" +doctorType +"/"+doctorBranch+"/" +pagelength +"/" +page +"/"+doctorName+"/2/JJ";


      api.get(apiUrl).then((res)=>{
          this.recordcount = res.data.rowCount;
          this.consultants = res.data.listUser;
       
      })
    },

    next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      }
  },
  mounted(){
      this.consultantType = this.$route.params.consultant_type;
      let action = {
        component:ConsultantToolbar,
        props:{consultantType:this.consultantType}
      };

      setCurrentPageBreadcrumbs(labels.consultantTypes[this.consultantType]+" Listesi", ["Danışmanlar", "Danışman Listesi"],action);
      this.getConsultantList();
  }
  
  }
</script>
