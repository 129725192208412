<template>
    <div>
        <a :href="'/consultants/create?type='+consultantType" class="btn btn-sm btn-primary">
          Yeni Danışman Oluştur
        </a>
    </div>
</template>
<script>
export default {
    name:'ConsultantToolbar',
    props:['consultantType']
}
</script>